import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";

import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_ORDER_QUERY";
import { POProjectStatus } from "@sellernote/_shared/src/types/forwarding/adminOrder";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

export default function useCountingModalBody({
  setShowsCountingModal,
  POId,
  name,
  userId,
  POProjectStatus,
  hasSubPO,
  teamId,
  isOpenApiAuth,
}: {
  setShowsCountingModal: (value: boolean) => void;
  POId: number;
  name: string;
  userId: number;
  POProjectStatus: POProjectStatus;
  hasSubPO: boolean;
  teamId: number;
  isOpenApiAuth: boolean;
}) {
  const queryClient = useQueryClient();

  const { id }: { id: string } = useParams();

  const history = useHistory();

  const location = useLocation();

  const [isPlaned, setIsPlaned] = useState(false);
  const [count, setCount] = useState<number>(0);

  const { mutate: deleteSubPO } = ADMIN_ORDER_QUERY.useDeleteSubPO(POId);

  const { mutate: createSubPO } = ADMIN_ORDER_QUERY.useCreateSubPO(POId);

  const {
    UploadResponseSnackBar,
    setShowsErrorSnackBar,
    setErrorMessage,
    setShowsSuccessSnackBar,
  } = useUploadResponseSnackBar(() => setShowsCountingModal(false));

  const handleCountCancel = useCallback(() => {
    setCount(0);
    setShowsCountingModal(false);
  }, [setCount, setShowsCountingModal]);

  const getBidCreateLocationData = useCallback(() => {
    return Array.from({ length: count }, (currentValue, i) => ({
      tabLabel: i + 1,
      bidCreateFormData: {},
      disabled: false,
    }));
  }, [count]);

  const moveToBidCreatePage = useCallback(() => {
    const userData = {
      userId,
      userName: name,
      teamId,
    };

    const bidCreateData = {
      bidCreateData: getBidCreateLocationData(),
      POId,
      hasPO: true,
      tabsDefaultValue: 0,
      isSubPO: location.pathname.includes("/sub") ? true : false,
      isOpenApiAuth,
    };

    // 데이터를 문자열로 변환
    const serializedUserData = JSON.stringify(userData);

    const serializeBidCreatedData = JSON.stringify(bidCreateData);

    sessionStorage.setItem("sessionStorageShipmentCreateUserInfo", serializedUserData);

    sessionStorage.setItem("sessionStorageShipmentCreateInfo", serializeBidCreatedData);

    history.push(`/bid/create`);

    handleCountCancel();
  }, [
    userId,
    name,
    teamId,
    history,
    getBidCreateLocationData,
    POId,
    location.pathname,
    isOpenApiAuth,
    handleCountCancel,
  ]);

  const handleCreateBid = useCallback(() => {
    if (POProjectStatus !== "contactPartner") {
      setErrorMessage("파트너 컨택을 먼저 해야합니다.");
      setShowsErrorSnackBar(true);
      return;
    }

    if (!count && hasSubPO) {
      setErrorMessage("의뢰 수를 설정해주세요");
      setShowsErrorSnackBar(true);
      return;
    }

    /** 선적계획만 있는 상태에서 미선적 계획을 추가 */
    if (!count && isPlaned && !hasSubPO) {
      createSubPO(
        {},
        {
          onSuccess: () => {
            setShowsSuccessSnackBar(true);
            queryClient.invalidateQueries(
              ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({ id: Number(id) })
            );
          },

          onError: () => {
            setShowsErrorSnackBar(true);
          },
        }
      );
      return;
    }

    if (isPlaned) {
      createSubPO(
        {},
        {
          onSuccess: () => {
            moveToBidCreatePage();
          },

          onError: () => {
            setShowsErrorSnackBar(true);
          },
        }
      );
      return;
    }

    if (!isPlaned && hasSubPO && location.pathname.includes("/sub")) {
      deleteSubPO(
        {},
        {
          onSuccess: () => {
            moveToBidCreatePage();
          },

          onError: () => {
            setShowsErrorSnackBar(true);
          },
        }
      );

      return;
    }

    moveToBidCreatePage();
  }, [
    count,
    createSubPO,
    deleteSubPO,
    hasSubPO,
    id,
    isPlaned,
    location.pathname,
    moveToBidCreatePage,
    POProjectStatus,
    queryClient,
    setErrorMessage,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
  ]);

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setIsPlaned(checked);
  };

  const handleCountAdd = useCallback(() => {
    setCount(count + 1);
  }, [count, setCount]);

  const handleCountSubtract = useCallback(() => {
    setCount(count - 1);
  }, [count, setCount]);

  const ModalBody = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography padding={3} variant={"h6"}>
          실 선적계획이 있는 운송의뢰 수를 선택하세요.
        </Typography>

        <Grid container justifyContent={"space-around"} minWidth={300}>
          <IconButton
            size="large"
            disabled={!count}
            onClick={handleCountSubtract}
          >
            <RemoveCircleOutlineRoundedIcon fontSize="large" />
          </IconButton>

          <Typography variant="h2" component="span">
            {count}
          </Typography>

          <IconButton
            size="large"
            disabled={count === 99}
            onClick={handleCountAdd}
          >
            <AddCircleOutlineRoundedIcon fontSize="large" />
          </IconButton>
        </Grid>

        <FormControlLabel
          control={
            <Switch
              disabled={!location.pathname.includes("/sub") && hasSubPO}
              value={isPlaned}
              onChange={handleSwitchChange}
            />
          }
          sx={{ alignSelf: "flex-start", marginTop: 3 }}
          label="선적 미계획 건이 있는 경우"
        />

        <Grid>
          <Box display={"flex"} gap={2} mt={3}>
            <Button variant="outlined" onClick={handleCountCancel}>
              취소
            </Button>

            <Button variant="contained" onClick={handleCreateBid}>
              확인
            </Button>
          </Box>
        </Grid>

        {UploadResponseSnackBar}
      </Box>
    );
  }, [
    UploadResponseSnackBar,
    count,
    handleCountAdd,
    handleCountCancel,
    handleCountSubtract,
    handleCreateBid,
    hasSubPO,
    isPlaned,
    location.pathname,
  ]);

  return { ModalBody };
}
