import { useCallback, useMemo } from "react";
import { UseFormWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import {
  CREATE_BID_REQ_AIR,
  CREATE_BID_REQ_DESTINATION,
  CREATE_BID_REQ_LCL,
  CREATE_BID_REQ_ORIGIN,
  GET_FCL_ESTIMATE_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM } from "@sellernote/_shared/src/api-interfaces/shipda-api/quote";
import ADMIN_BID_CREATE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_CREATE_QUERY";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import {
  FreightType,
  Liner,
  Port,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  BidCreateFormData,
  BidCreateQuotationStorageData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  calculateCBM,
  calculateCW,
  calculateRTon,
  toFixedFloat,
  toKg,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import {
  getLinerId,
  getQuotationDataCurrency,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import {
  getCountryOfPort,
  getPortId,
  handleShipmentCreateSuccess,
} from "@sellernote/_shared/src/utils/forwarding/adminBidCreate";
import {
  findExchangeRate,
  getAllTotalItemPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import { getContainersInfoPayload } from "pages/bid/create/BidCreateForm/utils";

//TODO: 수출/수입 의뢰/견적에 차이가 있지만 거의 유사하여 통일 커스텀 훅을 만들어야 함
export default function useConfirmExportQuotationButton({
  portList,
  bidCreateFormData,
  freightType,
  watch,
  linerList,
  exchangeRateList,
  /** 실시간 견적 데이터 */
  estimate,
  hasPO,
  formIndex,
  sessionStorageQuotationInfo,
  sessionStorageShipmentCreateUserInfo,
  handleSessionStorageShipmentCreateInfoChange,
  sessionStorageShipmentCreateInfo,
}: {
  portList: Port[] | undefined;
  bidCreateFormData: BidCreateFormData;
  freightType: FreightType;
  watch: UseFormWatch<ApplyBidFormData>;
  linerList: Liner[] | undefined;
  exchangeRateList: ExchangeRate[] | undefined;
  estimate: GET_FCL_ESTIMATE_RES | undefined;
  hasPO?: boolean;
  formIndex: number;
  sessionStorageQuotationInfo: BidCreateQuotationStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
}) {
  const history = useHistory();

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { mutate: createExportBid, isLoading: createdExportBidLoading } =
    ADMIN_BID_CREATE_QUERY.useCreateExportBid();

  const {
    UploadResponseSnackBar: ResponseUploadSnackBar,
    setShowsErrorSnackBar,
  } = useUploadResponseSnackBar();

  const getQuotationInfo = useCallback(() => {
    return {
      currency: getQuotationDataCurrency({
        localFee: watch("localFeeData"),
        freightFee: watch("freightFeeData"),
        domesticFee: watch("domesticFeeData"),
        inlandFee: watch("inlandFeeData"),
        otherFee: watch("otherFeeData"),
        taxFee: watch("taxFeeData"),
        exchangeRateList: exchangeRateList,
      }),
      userId: sessionStorageShipmentCreateUserInfo.userId,
      freightFee: watch("freightFeeData"),
      domesticFee: watch("domesticFeeData"),
      localFee: watch("localFeeData"),
      taxFee: watch("taxFeeData"),
      inlandFee: watch("inlandFeeData"),
      otherFee: watch("otherFeeData"),
      freightPaymentType: watch("freightPaymentType"),
      comment: watch("comment") || "",
      startCountry:
        bidCreateFormData.startType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.startPort)
          : getCountryOfPort(portList, bidCreateFormData.startViaPort),
      startPortId: bidCreateFormData.startPort
        ? getPortId(portList, bidCreateFormData.startPort)
        : getPortId(portList, bidCreateFormData.startViaPort),
      endCountry:
        bidCreateFormData.endType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.endPort)
          : getCountryOfPort(portList, bidCreateFormData.endViaPort),
      endPortId: bidCreateFormData.endPort
        ? getPortId(portList, bidCreateFormData.endPort)
        : getPortId(portList, bidCreateFormData.endViaPort),
      endZoneId: bidCreateFormData.zoneId,
      incoterms: bidCreateFormData.incoterms,
      freightType: freightType,
      volumn: "",
      liner: watch("liner"),
      linerId: !getLinerId(linerList, watch("liner"))
        ? undefined
        : getLinerId(linerList, watch("liner")),
      leadtime: watch("leadtime"),
      isTransit: watch("isTransit"),
      exchangeRate: findExchangeRate(exchangeRateList, "USD"),
      totalPrice: getAllTotalItemPriceOfFeeData(
        watch("freightFeeData"),
        watch("domesticFeeData"),
        watch("localFeeData"),
        watch("taxFeeData"),
        watch("inlandFeeData"),
        watch("otherFeeData")
      ),
      expiredAt: watch("expiredAt"),
    };
  }, [
    watch,
    exchangeRateList,
    sessionStorageShipmentCreateUserInfo.userId,
    bidCreateFormData.startType,
    bidCreateFormData.startPort,
    bidCreateFormData.startViaPort,
    bidCreateFormData.endType,
    bidCreateFormData.endPort,
    bidCreateFormData.endViaPort,
    bidCreateFormData.zoneId,
    bidCreateFormData.incoterms,
    portList,
    freightType,
    linerList,
  ]);

  const getRequestOriginData = useCallback(() => {
    const origin: CREATE_BID_REQ_ORIGIN = {
      startCountry:
        bidCreateFormData.startType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.startPort)
          : getCountryOfPort(portList, bidCreateFormData.startViaPort),
      startPortId:
        bidCreateFormData.startType !== "inland"
          ? getPortId(portList, bidCreateFormData.startPort)
          : undefined,
      startViaPortId:
        bidCreateFormData.startType === "inland"
          ? getPortId(portList, bidCreateFormData.startViaPort)
          : undefined,
      startAddress: bidCreateFormData.startAddress,
      startType: bidCreateFormData.startType,
      startAddressDetail: bidCreateFormData.startAddressDetail,
      zoneId:
        bidCreateFormData.startType === "inland"
          ? bidCreateFormData.zoneId
          : undefined,
    };

    return origin;
  }, [
    bidCreateFormData.startAddress,
    bidCreateFormData.startAddressDetail,
    bidCreateFormData.startPort,
    bidCreateFormData.startType,
    bidCreateFormData.startViaPort,
    bidCreateFormData.zoneId,
    portList,
  ]);

  const getRequestDestinationData = useCallback(() => {
    const origin: CREATE_BID_REQ_DESTINATION = {
      endCountry:
        bidCreateFormData.endType !== "inland"
          ? getCountryOfPort(portList, bidCreateFormData.endPort)
          : getCountryOfPort(portList, bidCreateFormData.endViaPort),
      endPortId:
        bidCreateFormData.endType !== "inland"
          ? getPortId(portList, bidCreateFormData.endPort)
          : undefined,
      endViaPortId:
        bidCreateFormData.endType === "inland"
          ? getPortId(portList, bidCreateFormData.endViaPort)
          : undefined,
      endAddress: bidCreateFormData.endAddress,
      endType: bidCreateFormData.endType,
    };

    return origin;
  }, [
    bidCreateFormData.endAddress,
    bidCreateFormData.endPort,
    bidCreateFormData.endType,
    bidCreateFormData.endViaPort,
    portList,
  ]);

  const getContainersInfo = useCallback(() => {
    // 오픈 API 유저일 때는 폼 데이터에 products가 존재하기 때문에 제거하고 컴마를 붙힌 네임을 생성한다.
    return bidCreateFormData.containersInfo?.map(
      ({ products, ...containerItem }) => {
        const joinedName =
          products
            ?.map((product) => {
              return product.productName;
            })
            .join(",") ?? "";

        return {
          ...containerItem,
          // OPEN API 유저가 아니라면 일반 name을 사용
          name: sessionStorageQuotationInfo.isOpenApiAuth
            ? joinedName
            : containerItem.name,
        };
      }
    );
  }, [
    bidCreateFormData.containersInfo,
    sessionStorageQuotationInfo.isOpenApiAuth,
  ]);

  const getUnitSupply = useCallback(
    (productsItem: SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM) => {
      const calculatedCBM =
        calculateCBM({
          type: "lcl",
          width: productsItem.horizontal || 0,
          height: productsItem.height || 0,
          depth: productsItem.vertical || 0,
          sizeUnit: productsItem.volumeUnit,
        }) || 0;

      const cbm = toFixedFloat(calculatedCBM * productsItem.quantity, 2) ?? 0;

      if (freightType === "LCL") {
        if (cargoInfoFormType === "totalVolume") {
          return calculateRTon(
            productsItem.cbm,
            toTon(productsItem.weight, productsItem.weightUnit)
          );
        }
        return calculateRTon(
          cbm ?? 0,
          toTon(productsItem.weight, productsItem.weightUnit)
        );
      }

      if (cargoInfoFormType === "totalVolume" && productsItem) {
        return (
          toFixedFloat(
            calculateCW({
              type: "cbm",
              cbm: productsItem.cbm,
              weight: toKg(productsItem.weight, productsItem.weightUnit),
            }),
            2
          ) ?? 0
        );
      }
      return (
        toFixedFloat(
          calculateCW({
            type: "cbm",
            cbm: cbm ?? 0,
            weight: toTon(productsItem.weight ?? 0, productsItem.weightUnit),
          }),
          2
        ) ?? 0
      );
    },
    [cargoInfoFormType, freightType]
  );

  const getProductsInfoWithUnitSupply = useCallback(() => {
    return bidCreateFormData.productsInfo?.map((productsItem) => {
      if (cargoInfoFormType === "packaging") {
        return {
          ...productsItem,
          unitSupply: getUnitSupply(productsItem),
          cbm:
            toFixedFloat(
              calculateCBM({
                type: "lcl",
                width: productsItem.horizontal || 0,
                height: productsItem.height || 0,
                depth: productsItem.vertical || 0,
                sizeUnit: productsItem.volumeUnit,
              }) ?? 0 * productsItem.quantity,
              2
            ) ?? 0,
        };
      }
      return {
        ...productsItem,
        // packaging에서는 필요없는 정보
        volumeUnit: undefined,
        height: undefined,
        vertical: undefined,
        horizontal: undefined,
        unitSupply: getUnitSupply(productsItem),
      };
    });
  }, [bidCreateFormData.productsInfo, cargoInfoFormType, getUnitSupply]);

  const getRequestLclData = useCallback(() => {
    const lcl: CREATE_BID_REQ_LCL = {
      supply: bidCreateFormData.supply,
      totalCBM:
        cargoInfoFormType === "totalVolume"
          ? undefined
          : bidCreateFormData.totalCBM,
      totalWeight:
        cargoInfoFormType === "totalVolume"
          ? undefined
          : bidCreateFormData.totalWeight,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return lcl;
  }, [
    cargoInfoFormType,
    bidCreateFormData.supply,
    bidCreateFormData.totalCBM,
    bidCreateFormData.totalWeight,
    getProductsInfoWithUnitSupply,
  ]);

  const getRequestAirData = useCallback(() => {
    const air: CREATE_BID_REQ_AIR = {
      supply: bidCreateFormData.supply,
      productsInfo: getProductsInfoWithUnitSupply(),
    };

    return air;
  }, [bidCreateFormData.supply, getProductsInfoWithUnitSupply]);

  const handleExportQuotationConfirm = useCallback(() => {
    createExportBid(
      {
        pathParams: {
          userId: sessionStorageShipmentCreateUserInfo.userId,
          teamId: sessionStorageShipmentCreateUserInfo.teamId,
        },
        origin: getRequestOriginData(),
        destination: getRequestDestinationData(),
        fcl:
          bidCreateFormData.freightType === "FCL"
            ? {
                containersInfo: getContainersInfoPayload({
                  containersInfo: bidCreateFormData.containersInfo,
                  isOpenApiAuth: sessionStorageShipmentCreateInfo.isOpenApiAuth,
                }),
              }
            : undefined,
        lcl:
          bidCreateFormData.freightType === "LCL"
            ? getRequestLclData()
            : undefined,
        air:
          bidCreateFormData.freightType === "AIR"
            ? getRequestAirData()
            : undefined,
        freightType: bidCreateFormData.freightType,
        transportType: bidCreateFormData.freightType === "AIR" ? "air" : "sea",
        /** 현재는 1로 고정 */
        importerCount: 1,
        additional: {
          importCustoms: bidCreateFormData.importCustoms ?? false,
          useVGM: bidCreateFormData.useVGM ?? false,
          exportCustoms: bidCreateFormData.exportCustoms ?? false,
          hopeCargoInsurance: bidCreateFormData.hopeCargoInsurance ?? false,
        },

        checkpoint: {
          cfsReceiving: bidCreateFormData.cfsReceiving,
          containerDevanning:
            freightType === "FCL"
              ? bidCreateFormData.containerDevanning === "TRUE"
              : false,
          inlandTransportType:
            !bidCreateFormData.inlandTransportType ||
            bidCreateFormData.inlandTransportType === "none"
              ? undefined
              : bidCreateFormData.inlandTransportType,
          containerStuffing:
            freightType === "FCL"
              ? bidCreateFormData.containerStuffing === "TRUE"
              : false,
        },
        incoterms: bidCreateFormData.incoterms,
        isFixed: false,
        fareId: estimate?.fare?.id,
        estimateLogId: estimate?.estimateLogId,
        price: getAllTotalItemPriceOfFeeData(
          watch("freightFeeData"),
          watch("domesticFeeData"),
          watch("localFeeData"),
          watch("taxFeeData"),
          watch("inlandFeeData"),
          watch("otherFeeData")
        ),
        quotationInfoForDownload: estimate?.quotationInfoForDownload,
        quotationInfo: getQuotationInfo(),
        invoicePrice: sessionStorageQuotationInfo.isOpenApiAuth
          ? bidCreateFormData.invoicePrice
          : undefined,
      },
      {
        onSuccess: (response) => {
          const handleMoveToOrderPage = () => {
            history.push(`/order/${sessionStorageQuotationInfo.POId}`);
          };

          const handleMoveToShipmentCreatePage = () => {
            history.replace("/bid/create");
          };

          const handleMoveToShipmentDetailPage = () => {
            history.replace(`/bid/detail/${response.data.id}`);
          };

          handleShipmentCreateSuccess({
            hasPO,
            sessionStorageShipmentCreateInfo,
            formIndex,
            formData: bidCreateFormData,
            handleMoveToOrderPage,
            handleMoveToShipmentCreatePage,
            handleMoveToShipmentDetailPage,
            handleSessionStorageShipmentCreateInfoChange,
          });
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  }, [
    createExportBid,
    sessionStorageShipmentCreateUserInfo.userId,
    sessionStorageShipmentCreateUserInfo.teamId,
    getRequestOriginData,
    getRequestDestinationData,
    bidCreateFormData,
    getRequestLclData,
    getRequestAirData,
    freightType,
    estimate?.fare?.id,
    estimate?.estimateLogId,
    estimate?.quotationInfoForDownload,
    watch,
    getQuotationInfo,
    sessionStorageQuotationInfo.isOpenApiAuth,
    sessionStorageQuotationInfo.POId,
    hasPO,
    sessionStorageShipmentCreateInfo,
    formIndex,
    handleSessionStorageShipmentCreateInfoChange,
    history,
    setShowsErrorSnackBar,
  ]);

  const ConfirmExportQuotationButton = useMemo(() => {
    return (
      <Grid item>
        <Button
          type="submit"
          variant="contained"
          disabled={createdExportBidLoading}
        >
          {createdExportBidLoading ? (
            <CircularProgress size={25} />
          ) : (
            "견적확정"
          )}
        </Button>

        {ResponseUploadSnackBar}
      </Grid>
    );
  }, [ResponseUploadSnackBar, createdExportBidLoading]);

  return { ConfirmExportQuotationButton, handleExportQuotationConfirm };
}
