import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Button, Checkbox } from "@mui/material";

import { GET_ADMIN_BID_PARTNER_QUOTE_LIST_SEARCH_PARAMS } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import useSet from "@sellernote/_shared/src/hooks/common/useSet";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import {
  AdminBidDetail,
  AdminBidTransportMode,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  PartnerManagerItem,
  PartnerQuoteListItem,
} from "@sellernote/_shared/src/types/forwarding/partner";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { getMainManagerInfo } from "@sellernote/_shared/src/utils/forwarding/partner";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import PartnerManagersListModal from "@sellernote/_shared-for-forwarding-admin/src/containers/PartnerManagersListModal";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";

import { renderCountryInCharge } from "pages/partner/utils";

import RequestQuoteToPartnerModal from "../../components/RequestQuoteToPartnerModal";

type CellId =
  | keyof PartnerQuoteListItem
  | "mainManagerName"
  | "mainManagerPosition"
  | "mainManagerPhone"
  | "mainManagerEmail"
  | "showsManagersModal"
  | "checkbox";

function PartnerQuoteListModal({
  showsPartnerQuoteListModal,
  setShowsPartnerQuoteListModal,
  bidDetail,
  transportMode,
  handlePartnerQuoteListRefetch,
}: {
  showsPartnerQuoteListModal: boolean;
  setShowsPartnerQuoteListModal: Dispatch<SetStateAction<boolean>>;
  transportMode: AdminBidTransportMode;
  bidDetail: AdminBidDetail;
  handlePartnerQuoteListRefetch: () => void;
}) {
  const [showsPartnerManagersListModal, setShowsPartnerManagersListModal] =
    useState(false);

  const [managers, setManagers] = useState<PartnerManagerItem[]>([]);

  const [showsRequestQuoteToPartnerModal, setShowsRequestQuoteToPartnerModal] =
    useState(false);

  const { set: checkBoxSet, toggle: toggleCheckBox } = useSet<number>();

  const {
    array: managerCheckBoxArr,
    set: managerCheckBoxSet,
    toggle: toggleManagerCheckBox,
    init: initManagerCheckBoxSet,
  } = useSet<number>();

  const termSearchTypeOptions: TermSearchType<GET_ADMIN_BID_PARTNER_QUOTE_LIST_SEARCH_PARAMS>[] =
    [
      {
        label: "회사명",
        value: "companyName",
      },
      {
        label: "담당자명",
        value: "managerName",
      },
    ];
  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { data: partnerQuoteList } =
    ADMIN_BID_QUERY.useGetAdminBidPartnerQuoteList({
      bidId: bidDetail.id,
      country: bidDetail.isImport
        ? bidDetail.startCountry
        : bidDetail.endCountry,
      transportMode,
      ...objectWithTermSearchTypeKey,
    });

  const { data: countryData = [] } = ADMIN_COMMON_QUERY.useGetCountryList();

  const getMainManager = useCallback((managers: PartnerManagerItem[]) => {
    const mainManager = managers.find((managerItem: PartnerManagerItem) => {
      return managerItem.isMain === true;
    });

    if (mainManager) {
      return mainManager.name;
    }
    return "";
  }, []);

  const getEmailList = useCallback(
    (managers: PartnerManagerItem[]) => {
      const managerEmails = managers
        .filter((managerItem) => {
          return managerCheckBoxSet.has(managerItem.id);
        })
        .map((managerItem: PartnerManagerItem) => {
          return managerItem.email;
        });
      return managerEmails;
    },
    [managerCheckBoxSet]
  );

  const receiverList = useMemo(() => {
    return partnerQuoteList?.list
      .filter((v) => {
        return checkBoxSet.has(v.id);
      })
      .map((v) => {
        return {
          language: v.language,
          mainManager: getMainManager(v.managers),
          needsPlainTextEmail: v.needsPlainTextEmail,
          name: v.name,
          emailList: getEmailList(v.managers),
          partnerCompany: v.id,
        };
      });
  }, [checkBoxSet, getEmailList, getMainManager, partnerQuoteList?.list]);

  const handlePartnerManagersListModalOpen = useCallback(
    (managers: PartnerManagerItem[]) => {
      return () => {
        setManagers(managers);
        setShowsPartnerManagersListModal(true);
      };
    },
    []
  );

  const handlePartnerCheckboxClick = useCallback(
    (partnerQuoteListItem: PartnerQuoteListItem) => {
      if (checkBoxSet.has(partnerQuoteListItem.id)) {
        const idsToCheck = partnerQuoteListItem.managers.map((managersItem) => {
          return managersItem.id;
        });

        const filterManagerCheckBoxArr = managerCheckBoxArr.filter((v) => {
          return !idsToCheck.includes(v);
        });

        initManagerCheckBoxSet(filterManagerCheckBoxArr);
        toggleCheckBox(partnerQuoteListItem.id);
      } else {
        toggleCheckBox(partnerQuoteListItem.id);

        const idsToCheck = partnerQuoteListItem.managers.map((managersItem) => {
          return managersItem.id;
        });

        initManagerCheckBoxSet([...managerCheckBoxArr, ...idsToCheck]);
      }
    },
    [checkBoxSet, initManagerCheckBoxSet, managerCheckBoxArr, toggleCheckBox]
  );

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      { id: "checkbox", disablePadding: false, label: "선택", width: 50 },
      {
        id: "requestQuoteFlag",
        disablePadding: false,
        label: "발송 유무",
        width: 100,
      },
      { id: "id", disablePadding: false, label: "ID", width: 80 },
      { id: "name", disablePadding: false, label: "회사명" },
      { id: "language", disablePadding: false, label: "언어", width: 70 },
      {
        id: "countries",
        disablePadding: false,
        label: "담당 국가",
        width: 140,
      },
      {
        id: "mainManagerName",
        disablePadding: false,
        label: "담당자 이름",
        width: 100,
      },
      {
        id: "mainManagerPosition",
        disablePadding: false,
        label: "직함",
        width: 100,
      },
      {
        id: "mainManagerPhone",
        disablePadding: false,
        label: "전화번호",
        width: 150,
      },
      {
        id: "mainManagerEmail",
        disablePadding: false,
        label: "이메일",
        width: 200,
      },
      {
        id: "showsManagersModal",
        disablePadding: false,
        label: "매니저",
        width: 100,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!partnerQuoteList?.list) return [];

    return partnerQuoteList.list.map((partnerQuoteListItem) => {
      const row: TableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(partnerQuoteListItem.id)}
            onClick={(e) => {
              e.stopPropagation();
              handlePartnerCheckboxClick(partnerQuoteListItem);
            }}
          />
        ),
        requestQuoteFlag: changeBooleanValueToKr(
          partnerQuoteListItem.requestQuoteFlag
        ),
        id: partnerQuoteListItem.id,
        name: partnerQuoteListItem.name,
        language: partnerQuoteListItem.language,
        countries: renderCountryInCharge({
          countryDataList: countryData,
          countryInChargeOfPartner: partnerQuoteListItem.countries,
        }),
        mainManagerName: getMainManagerInfo(
          "name",
          partnerQuoteListItem.managers
        ),
        mainManagerPosition: getMainManagerInfo(
          "position",
          partnerQuoteListItem.managers
        ),
        mainManagerPhone: getMainManagerInfo(
          "phone",
          partnerQuoteListItem.managers
        ),
        mainManagerEmail: getMainManagerInfo(
          "email",
          partnerQuoteListItem.managers
        ),
        showsManagersModal: (
          <Button
            onClick={handlePartnerManagersListModalOpen(
              partnerQuoteListItem.managers
            )}
          >
            보기
          </Button>
        ),
      };
      return row;
    });
  }, [
    checkBoxSet,
    countryData,
    handlePartnerCheckboxClick,
    handlePartnerManagersListModalOpen,
    partnerQuoteList?.list,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsPartnerQuoteListModal(false);
  }, [setShowsPartnerQuoteListModal]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Table
          toolbarItems={{
            right: [
              TermSearchPanel,
              <Button
                key="request-button"
                variant="contained"
                onClick={() => setShowsRequestQuoteToPartnerModal(true)}
              >
                견적 요청
              </Button>,
            ],
          }}
          sx={{ height: "500px", width: "1440px" }}
          title="견적 파트너 리스트"
          headCells={headCells}
          rows={rows}
        />

        {showsPartnerManagersListModal && (
          <PartnerManagersListModal
            showsPartnerManagersListModal={showsPartnerManagersListModal}
            setShowsPartnerManagersListModal={setShowsPartnerManagersListModal}
            managers={managers}
            partnerCheckboxSet={checkBoxSet}
            managerCheckBoxSet={managerCheckBoxSet}
            toggleManagerCheckBox={toggleManagerCheckBox}
            initManagerCheckBoxSet={initManagerCheckBoxSet}
          />
        )}

        {showsRequestQuoteToPartnerModal && receiverList && (
          <RequestQuoteToPartnerModal
            isBidCopy={false}
            receiverList={receiverList}
            bidDetail={bidDetail}
            showsRequestQuoteModal={showsRequestQuoteToPartnerModal}
            setShowsRequestQuoteModal={setShowsRequestQuoteToPartnerModal}
            handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
          />
        )}
      </>
    );
  }, [
    TermSearchPanel,
    headCells,
    rows,
    showsPartnerManagersListModal,
    managers,
    checkBoxSet,
    managerCheckBoxSet,
    toggleManagerCheckBox,
    initManagerCheckBoxSet,
    showsRequestQuoteToPartnerModal,
    receiverList,
    bidDetail,
    handlePartnerQuoteListRefetch,
  ]);

  return (
    <Modal
      isOpened={showsPartnerQuoteListModal}
      handleClose={handleModalClose}
      modalBody={ModalBody}
    />
  );
}

export default PartnerQuoteListModal;
