import { useState } from "react";

import { SnackbarOpenProps } from "../types";

export default function useHandleAccountSnackbar() {
  const [title, setTitle] = useState("요청에 성공했습니다.");
  const [isError, setIsError] = useState(false);
  const [showsSnackbar, setShowsSnackbar] = useState(false);

  const openSnackbar = ({ title, isError = true }: SnackbarOpenProps) => {
    setTitle(title);
    setIsError(isError);
    setShowsSnackbar(true);
  };

  const handleSnackbarClose = () => {
    setShowsSnackbar(false);
  };

  return {
    showsSnackbar,
    title,
    isError,
    openSnackbar,
    handleSnackbarClose,
  };
}
