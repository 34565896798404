import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import { CHECK_IS_OCEANTICKET_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBidCreate";
import { SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM } from "@sellernote/_shared/src/api-interfaces/shipda-api/quote";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { FreightType, Port } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateFormData,
  BidCreateQuotationStorageData,
  BidCreateStorageData,
  BidCreateUserData,
  ShipmentCreateProductsInfo,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import {
  calculateCBM,
  calculateCW,
  calculateRTon,
  toFixedFloat,
  toKg,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import {
  checkIsDoubleFromProductsInfo,
  getNewShipmentCreateInfo,
  getPortId,
  getProductInfoTotalQuantity,
} from "@sellernote/_shared/src/utils/forwarding/adminBidCreate";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import { getContainersInfoPayload } from "../utils";

export default function useMoveToQuotationButton({
  portList,
  formData,
  isOceanticket,
  freightType,
  checkBlankInBidCreateForm,
  formIndex,
  sessionStorageShipmentCreateInfo,
  sessionStorageShipmentCreateUserInfo,
  handleSessionStorageShipmentCreateInfoChange,
}: {
  portList: Port[];
  formData: BidCreateFormData;
  isOceanticket: CHECK_IS_OCEANTICKET_RES;
  freightType: FreightType;
  checkBlankInBidCreateForm: (formData: BidCreateFormData) => boolean;
  formIndex: number;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
}) {
  const history = useHistory();

  const [_, setSessionStorageQuotationInfo] =
    useSessionStorage<BidCreateQuotationStorageData | null>(
      "sessionStorageQuotationInfo"
    );

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const {
    UploadResponseSnackBar: ResponseSnackBar,
    setShowsErrorSnackBar,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  const getSearchEstimateCommonPayload = useCallback(() => {
    const payload = {
      userId: sessionStorageShipmentCreateUserInfo.userId,
      needFTACertificateAgency: formData.needFTACertificateAgency,
      endPort:
        formData.endType !== "inland"
          ? getPortId(portList, formData.endPort)
          : undefined,
      endViaPort:
        formData.endType === "inland"
          ? getPortId(portList, formData.endViaPort)
          : undefined,
      zoneId: formData.endType === "inland" ? formData.zoneId : undefined,
      endAddress: formData.endAddress,
      containDomesticCharge: formData.containDomesticFee
        ? formData.containDomesticFee === "TRUE"
        : false,
      incoterms: formData.incoterms,
      wantInsurance: formData.hopeCargoInsurance,
      containCustoms: formData.containCustoms,
      hasCargoDangerous: (() => {
        const target =
          freightType === "FCL"
            ? formData.containersInfo
            : formData.productsInfo;

        return target?.some((v) => !!v.isDangerous);
      })(),

      startAddress: formData.startAddress,
    };

    return payload;
  }, [
    sessionStorageShipmentCreateUserInfo.userId,
    formData.needFTACertificateAgency,
    formData.endType,
    formData.endPort,
    formData.endViaPort,
    formData.zoneId,
    formData.endAddress,
    formData.containDomesticFee,
    formData.incoterms,
    formData.hopeCargoInsurance,
    formData.containCustoms,
    formData.startAddress,
    formData.containersInfo,
    formData.productsInfo,
    portList,
    freightType,
  ]);

  const getSearchEstimateFclPayload = useCallback(() => {
    const commonPayload = getSearchEstimateCommonPayload();

    const containersInfo = getContainersInfoPayload({
      containersInfo: formData.containersInfo,
      isOpenApiAuth: sessionStorageShipmentCreateInfo.isOpenApiAuth,
    });

    const fclPayload = {
      ...commonPayload,
      containLSS: formData.containLss ? formData.containLss === "TRUE" : false,
      startPort:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : getPortId(portList, formData.startViaPort),
      containerInfo: containersInfo,
      isContainerEnterable: formData.containerAccessable
        ? formData.containerAccessable === "TRUE"
        : undefined,
      itemNameList: containersInfo?.map((v) => {
        return v.name;
      }),
      containOceanFreightCharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : false,
    };

    return fclPayload;
  }, [
    formData.containLss,
    formData.containOceanSurcharge,
    formData.containerAccessable,
    formData.containersInfo,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    getSearchEstimateCommonPayload,
    portList,
    sessionStorageShipmentCreateInfo.isOpenApiAuth,
  ]);

  const getSearchEstimateLclPayload = useCallback(() => {
    const commonPayload = getSearchEstimateCommonPayload();

    /** 의뢰 생성 폼에서 검증하기 때문에 타입 단언 사용 */
    const productsInfo = formData.productsInfo as ShipmentCreateProductsInfo[];

    const lclPayload = {
      ...commonPayload,
      startPort:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : getPortId(portList, formData.startViaPort),
      productsInfo: productsInfo,
      rton: formData.supply,
      /** 의뢰 생성 폼에서 검증하기 때문에 타입 단언 사용 */
      weight: formData.totalWeight as number,
      /** 요청시에는 체크값과 반대로 보내야 한다. */
      hasCargoCannotStack: !checkIsDoubleFromProductsInfo({
        productsInfo: formData?.productsInfo,
      }),
      containerCBM: formData.totalCBM,
      inlandType: formData.inlandType,
      itemNameList: productsInfo?.map((v) => {
        return v.name;
      }),
      containLSS: formData.containLss ? formData.containLss === "TRUE" : false,
      containOceanFreightCharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : false,
    };

    return lclPayload;
  }, [
    formData.containLss,
    formData.containOceanSurcharge,
    formData.inlandType,
    formData.productsInfo,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    formData.supply,
    formData.totalCBM,
    formData.totalWeight,
    getSearchEstimateCommonPayload,
    portList,
  ]);

  const getSearchEstimateAirPayload = useCallback(() => {
    const commonPayload = getSearchEstimateCommonPayload();

    /** 의뢰 생성 폼에서 검증하기 때문에 타입 단언 사용 */
    const productsInfo = formData.productsInfo as ShipmentCreateProductsInfo[];

    const airPayload = {
      ...commonPayload,
      startPort:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : getPortId(portList, formData.startViaPort),
      productsInfo,
      cw: formData.supply,
      // totalWeight는 따로 입력하지 않고 품목 입력값 합을 통해 kg기준으로 저장 요청 시에는 TON으로 다시 계산
      weight: formData.totalWeight
        ? // payload 요청 시 소숫점 2자리까지 줄여서 보내야한다.
          toFixedFloat(toTon(formData.totalWeight ?? 0, "KG"), 2) ?? 0
        : 0,
      /** 요청시에는 체크값과 반대로 보내야 한다. */
      hasCargoCannotStack: !checkIsDoubleFromProductsInfo({
        productsInfo: formData?.productsInfo,
      }),
      containerCBM: formData.totalCBM as number,
      hasCargoRefrigeration: formData.needRefrigeration,
      productCnt: getProductInfoTotalQuantity({
        productsInfo: formData?.productsInfo,
      }),
      itemNameList: productsInfo?.map((v) => {
        return v.name;
      }),
      inlandType: formData.inlandType,
    };

    return airPayload;
  }, [
    formData.inlandType,
    formData.needRefrigeration,
    formData.productsInfo,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    formData.supply,
    formData.totalCBM,
    formData.totalWeight,
    getSearchEstimateCommonPayload,
    portList,
  ]);

  const getSearchEstimateOceanticketPayload = useCallback(() => {
    const commonPayload = getSearchEstimateCommonPayload();

    /** 의뢰 생성 폼에서 검증하기 때문에 타입 단언 사용 */
    const productsInfo = formData.productsInfo as ShipmentCreateProductsInfo[];

    const oceanticketPayload = {
      ...commonPayload,
      startPort:
        formData.startType !== "inland"
          ? getPortId(portList, formData.startPort)
          : undefined,
      startViaPort:
        formData.startType === "inland"
          ? getPortId(portList, formData.startViaPort)
          : undefined,
      productsInfo,
      rton: formData.supply,
      /** 의뢰 생성 폼에서 validation으로 검증하기 때문에 타입 단언 사용 */
      weight: formData.totalWeight as number,
      /** 요청시에는 체크값과 반대로 보내야 한다. */
      hasCargoCannotStack: !checkIsDoubleFromProductsInfo({
        productsInfo: formData?.productsInfo,
      }),
      containerCBM: formData.totalCBM,
      inlandType: formData.inlandType,
      itemNameList: productsInfo?.map((v) => {
        return v.name;
      }),
      containLSS: formData.containLss ? formData.containLss === "TRUE" : false,
      containOceanFreightCharge: formData.containOceanSurcharge
        ? formData.containOceanSurcharge === "TRUE"
        : false,
    };

    return oceanticketPayload;
  }, [
    formData.containLss,
    formData.containOceanSurcharge,
    formData.inlandType,
    formData.productsInfo,
    formData.startPort,
    formData.startType,
    formData.startViaPort,
    formData.supply,
    formData.totalCBM,
    formData.totalWeight,
    getSearchEstimateCommonPayload,
    portList,
  ]);

  const getUnitSupply = useCallback(
    (productsItem: SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM) => {
      const calculatedCBM =
        calculateCBM({
          type: "lcl",
          width: productsItem.horizontal || 0,
          height: productsItem.height || 0,
          depth: productsItem.vertical || 0,
          sizeUnit: productsItem.volumeUnit,
        }) || 0;

      const cbm = toFixedFloat(calculatedCBM * productsItem.quantity, 2) ?? 0;

      if (freightType === "LCL") {
        if (cargoInfoFormType === "totalVolume") {
          return calculateRTon(
            productsItem.cbm,
            toTon(productsItem.weight, productsItem.weightUnit)
          );
        }
        return calculateRTon(
          cbm ?? 0,
          toTon(productsItem.weight, productsItem.weightUnit)
        );
      }

      if (cargoInfoFormType === "totalVolume" && productsItem) {
        return (
          toFixedFloat(
            calculateCW({
              type: "cbm",
              cbm: productsItem.cbm,
              weight: toKg(productsItem.weight, productsItem.weightUnit),
            }),
            2
          ) ?? 0
        );
      }
      return (
        toFixedFloat(
          calculateCW({
            type: "cbm",
            cbm: cbm ?? 0,
            weight: toTon(productsItem.weight ?? 0, productsItem.weightUnit),
          }),
          2
        ) ?? 0
      );
    },
    [cargoInfoFormType, freightType]
  );

  const getProductsInfoWithUnitSupply = useCallback(() => {
    return formData.productsInfo?.map((productsItem) => {
      if (cargoInfoFormType === "packaging") {
        return {
          ...productsItem,
          unitSupply: getUnitSupply(productsItem),
          cbm:
            toFixedFloat(
              calculateCBM({
                type: "lcl",
                width: productsItem.horizontal || 0,
                height: productsItem.height || 0,
                depth: productsItem.vertical || 0,
                sizeUnit: productsItem.volumeUnit,
              }) ?? 0 * productsItem.quantity,
              2
            ) ?? 0,
        };
      }
      return {
        ...productsItem,
        // packaging에서는 필요없는 정보
        volumeUnit: undefined,
        height: undefined,
        vertical: undefined,
        horizontal: undefined,
        unitSupply: getUnitSupply(productsItem),
      };
    });
  }, [cargoInfoFormType, formData.productsInfo, getUnitSupply]);

  // TODO: 함수명 변경필요
  const getBidCreateFormData = useCallback(() => {
    if (freightType !== "FCL") {
      const productsInfo = getProductsInfoWithUnitSupply();

      const containerAccessable =
        formData.freightType === "FCL" && formData.endType === "inland"
          ? formData.containerAccessable
          : undefined;

      return { ...formData, productsInfo, containerAccessable };
    }

    return formData;
  }, [formData, freightType, getProductsInfoWithUnitSupply]);

  const handleButtonClick = useCallback(() => {
    // 도착지 셀렉트에서 입력만 하고 선택하지 않았을 때
    if (!formData.zoneId && formData.endAddress) {
      setErrorMessage("도착지 주소가 선택되지 않았습니다. 확인해주세요");
      setShowsErrorSnackBar(true);

      return;
    }

    const newShipmentCreateInfo = getNewShipmentCreateInfo({
      sessionStorageShipmentCreateInfo,
      formIndex,
      formData: getBidCreateFormData(),
      disabled: false,
    });

    const searchEstimatePayload = (() => {
      if (freightType === "FCL")
        return { fclSearchEstimatePayload: getSearchEstimateFclPayload() };

      if (freightType === "LCL" && !isOceanticket)
        return { lclSearchEstimatePayload: getSearchEstimateLclPayload() };

      if (freightType === "AIR")
        return { airSearchEstimatePayload: getSearchEstimateAirPayload() };

      if (freightType === "LCL" && isOceanticket)
        return {
          oceanticketEstimatePayload: getSearchEstimateOceanticketPayload(),
        };

      return {};
    })();

    const quotationInfo = {
      ...searchEstimatePayload,
      isOceanticket: isOceanticket,
      ...newShipmentCreateInfo,
    };

    /** 견적서 페이지에서 의뢰생성으로 돌아올 경우 입력한 값을 기억하기 위해 새로운 폼 데이터로 저장 */
    handleSessionStorageShipmentCreateInfoChange(newShipmentCreateInfo);

    /** 견적서 페이지에서 의뢰생성으로 돌아올 경우 체크포인트에서 조건에 따른 셋팅을 막기위한 트리거 */
    sessionStorage.setItem("hasVisitedQuotationPage", "TRUE");

    /** 견적서 페이지에서 사용하기 위한 정보를 저장 */
    setSessionStorageQuotationInfo(quotationInfo);

    history.push(`/bid/create/quotation/${freightType}`);

    return;
  }, [
    formData.endAddress,
    formData.zoneId,
    formIndex,
    freightType,
    getBidCreateFormData,
    getSearchEstimateAirPayload,
    getSearchEstimateFclPayload,
    getSearchEstimateLclPayload,
    getSearchEstimateOceanticketPayload,
    handleSessionStorageShipmentCreateInfoChange,
    history,
    isOceanticket,
    setErrorMessage,
    setSessionStorageQuotationInfo,
    setShowsErrorSnackBar,
    sessionStorageShipmentCreateInfo,
  ]);

  const MoveToQuotationButton = useMemo(() => {
    return (
      <Grid item>
        <Button
          disabled={checkBlankInBidCreateForm(formData)}
          color="success"
          onClick={handleButtonClick}
          variant="contained"
        >
          견적 확인
        </Button>

        {ResponseSnackBar}
      </Grid>
    );
  }, [
    ResponseSnackBar,
    checkBlankInBidCreateForm,
    formData,
    handleButtonClick,
  ]);

  return {
    MoveToQuotationButton,
  };
}
