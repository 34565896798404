import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import { CREATE_ACCOUNT_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminAccount";
import ADMIN_ACCOUNT_QUERY, {
  ADMIN_ACCOUNT_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_ACCOUNT_QUERY";
import { AccountDetailInfo } from "@sellernote/_shared/src/types/forwarding/adminAccount";
import { FORWARDING_ACCOUNT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/account";

import {
  AccountCategoryState,
  SnackbarOpenProps,
  UpdateAccountDetailProps,
} from "../types";
import { getCategory, validateUpdateAccount } from "../utils";

import useGetAccountParams from "./useGetAccountParams";

const useUpdateAccountDetail = ({
  detailData,
  newAccountForm,
  category,
  openSnackbar,
}: {
  detailData: AccountDetailInfo | undefined;
  newAccountForm: CREATE_ACCOUNT_REQ;
  category: AccountCategoryState;
  openSnackbar: ({ title, isError }: SnackbarOpenProps) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate: updateDetail } = ADMIN_ACCOUNT_QUERY.useUpdateAccountDetail();

  const { params } = useGetAccountParams();

  const mappedTeamList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_TEAM_LIST
  );

  const mappedPartnerList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_PARTNER_LIST
  );

  const mappedTeamIdList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_TEAM_ID_LIST
  );

  const mappedPartnerIdList = useAtomValue(
    FORWARDING_ACCOUNT_ATOMS.MAPPED_PARTNER_ID_LIST
  );

  const handleDetailUpdate = useCallback(
    ({ id }: UpdateAccountDetailProps) => {
      const categoryForm = getCategory(category);

      if (!mappedTeamList.length && !mappedPartnerList.length) {
        openSnackbar({ title: "최소 하나의 팀 혹은 파트너를 선택해주세요." });

        return;
      }

      const isValidForm = validateUpdateAccount({
        detailData,
        newAccountForm,
        category,
        openSnackbar,
      });

      /** 유효성 검사에 실패했을 때 */
      if (!isValidForm) return;

      updateDetail(
        {
          ...newAccountForm,
          ...categoryForm,
          teams: mappedTeamIdList,
          partners: mappedPartnerIdList,
          pathParams: { id },
        },
        {
          onSuccess: () => {
            openSnackbar({
              title: "거래처 정보를 성공적으로 수정했습니다.",
              isError: false,
            });

            return Promise.all([
              queryClient.invalidateQueries(
                ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountList(params)
              ),

              queryClient.invalidateQueries(
                ADMIN_ACCOUNT_QUERY_KEY_GEN.getAccountDetail({
                  id,
                })
              ),

              queryClient.invalidateQueries(
                ADMIN_ACCOUNT_QUERY_KEY_GEN.getMappedAccount({
                  id,
                })
              ),
            ]);
          },
        }
      );
    },
    [
      category,
      detailData,
      mappedPartnerIdList,
      mappedPartnerList.length,
      mappedTeamIdList,
      mappedTeamList.length,
      newAccountForm,
      openSnackbar,
      params,
      queryClient,
      updateDetail,
    ]
  );

  return { handleDetailUpdate };
};

export default useUpdateAccountDetail;
