import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import { useAtomValue } from "jotai";

import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { Language } from "@sellernote/_shared/src/types/forwarding/customs";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import { useBidPartnerContext } from "../hooks/useBidPartnerContext";

import BidPartnerListModal from "./BidPartnerListModal";
import RequestEmailToPartnerForExport from "./RequestEmailToPartnerForExport";
import RequestEmailToPartnerForImport from "./RequestEmailToPartnerForImport";

// TODO: 여기서 파트너는 정산파트너인가..? 파트너 가 이곳 저곳에 있어서 헷갈리는데 형근님한테 물어봐야겠다.
// TODO: 컴포넌트 구조 개선해야한다... 하위모듈들을 책임성에 따라 나누고 여기서 BidPartnerProvider를 사용하는게 바람직할 것으로 보인다.
const PartnerDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  const queryClient = useQueryClient();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [showsBidPartnerModal, setShowsBidPartnerModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    setPartnerBusinessArea,
    setBidAccountPayableId,
    setLanguage,
    setPartnerName,
    showsRequestEmailToPartner,
    setShowsRequestEmailToPartner,
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
    setErrorMessage,
  } = useBidPartnerContext();

  const returnFreightPaymentType = () => {
    if (bidDetail.quotationsUser !== null) {
      if (bidDetail.quotationsUser[0].freightPaymentType === "pp") {
        return "pp";
      }
      if (bidDetail.quotationsUser[0].freightPaymentType === "cc") {
        return "cc";
      }
      return undefined;
    }
    return undefined;
  };

  const updateFreightPaymentType = async (e: SelectChangeEvent<string>) => {
    setLoading(true);
    try {
      await sendRequest({
        method: "put",
        path: `/bid/${bidDetail.id}/quotationUser/${bidDetail.quotationsUser[0].id}`,
        apiType: "ShipdaAdminDefaultNew",
        data: {
          quotationData: {
            freightPaymentType: e.target.value,
          },
        },
      });

      setShowsSuccessSnackBar(true);
      queryClient.invalidateQueries(
        ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
          bidId: bidDetail.id,
        })
      );
    } catch (err) {
      setShowsErrorSnackBar(true);
    }
  };

  const returnTransportType = () => {
    if (bidDetail.freightType === "FCL") {
      return "FCL";
    }
    if (
      bidDetail.freightType === "LCL" &&
      bidDetail.serviceType === "consolidation"
    ) {
      return "EXPRESS";
    }
    if (bidDetail.freightType === "AIR") {
      return "AIR";
    }
    return "LCL";
  };

  const checkDisabledAuthority = useMemo(() => {
    if (bidDetail.projectStatus === "settlementComplete") {
      if (currentAdminAuthInfo?.authority !== "master") {
        return true;
      }
      return false;
    }
    return false;
  }, [bidDetail.projectStatus, currentAdminAuthInfo?.authority]);

  const handleSelectPartnerModalOpen = useCallback(
    (accountPayableId: number, businessArea: PartnerBusinessArea) => {
      return () => {
        if (checkDisabledAuthority) {
          setErrorMessage("정산완료에서는 수정할 수 없습니다.");
          setShowsErrorSnackBar(true);
        } else {
          setBidAccountPayableId(accountPayableId);
          setPartnerBusinessArea(businessArea);
          setShowsBidPartnerModal(true);
        }
      };
    },
    [
      checkDisabledAuthority,
      setBidAccountPayableId,
      setErrorMessage,
      setPartnerBusinessArea,
      setShowsErrorSnackBar,
    ]
  );

  const handlesRequestEmailToPartnerModalOpen = useCallback(
    ({
      accountPayableId,
      businessArea,
      language,
      partnerName,
    }: {
      accountPayableId: number;
      businessArea: PartnerBusinessArea;
      language: Language;
      partnerName: string;
    }) => {
      return () => {
        if (!bidDetail.management.forwardingManagerId) {
          setErrorMessage("담당자를 등록해주세요");
          setShowsErrorSnackBar(true);
          return;
        }
        if (checkDisabledAuthority) {
          setErrorMessage("정산완료에서는 컨택 할 수 없습니다.");
          setShowsErrorSnackBar(true);
          return;
        }
        setBidAccountPayableId(accountPayableId);
        setPartnerBusinessArea(businessArea);
        setLanguage(language);
        setPartnerName(partnerName);
        setShowsRequestEmailToPartner(true);
      };
    },
    [
      bidDetail.management.forwardingManagerId,
      checkDisabledAuthority,
      setBidAccountPayableId,
      setErrorMessage,
      setLanguage,
      setPartnerBusinessArea,
      setPartnerName,
      setShowsErrorSnackBar,
      setShowsRequestEmailToPartner,
    ]
  );

  // 수입과 수출에서 반대 조건을 가진다. 수입cc(해외, 국내) 수입pp(해외만) 수출cc(해외만), 수출pp(해외, 국내)
  const showsDomesticPartner =
    (bidDetail.isImport &&
      bidDetail.quotationsUser[0].freightPaymentType === "cc") ||
    (!bidDetail.isImport &&
      bidDetail.quotationsUser[0].freightPaymentType === "pp");

  const showsForeignPartner =
    (bidDetail.isImport &&
      (bidDetail.quotationsUser[0].freightPaymentType === "cc" ||
        bidDetail.quotationsUser[0].freightPaymentType === "pp")) ||
    (!bidDetail.isImport &&
      (bidDetail.quotationsUser[0].freightPaymentType === "cc" ||
        bidDetail.quotationsUser[0].freightPaymentType === "pp"));

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">파트너 정보</Typography>

        {/* 지급 유형이 pp일 때는 해외파트너만 추가가 가능하고 cc일 때는 해외파트너와 국내파트너 모두 추가가 가능하다 */}
        <Box sx={{ display: "flex", gap: "4px" }}>
          {showsDomesticPartner && (
            <Button
              color="secondary"
              variant="contained"
              onClick={handleSelectPartnerModalOpen(0, "domestic")}
            >
              국내 파트너 추가
            </Button>
          )}

          {showsBidPartnerModal && (
            <BidPartnerListModal
              setShowsBidPartnerModal={setShowsBidPartnerModal}
              showsBidPartnerModal={showsBidPartnerModal}
              transportMode={returnTransportType()}
              region={bidDetail.locale}
            />
          )}

          {showsForeignPartner && (
            <Button
              variant="contained"
              onClick={handleSelectPartnerModalOpen(0, "foreign")}
            >
              해외 파트너 추가
            </Button>
          )}
        </Box>
      </Box>

      <Typography variant="subtitle1" component="div" sx={{ color: blue[700] }}>
        영업
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: 8,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            flex: "0 0 auto",
            minWidth: "200px",
          }}
        >
          <Typography variant="body2" component="div">
            지급유형:
          </Typography>

          <FormControl sx={{ width: 150 }} size="small">
            <Select
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              disabled={checkDisabledAuthority}
              defaultValue={returnFreightPaymentType()}
              onChange={(e) => updateFreightPaymentType(e)}
            >
              <MenuItem value="pp">P.P</MenuItem>
              <MenuItem value="cc">C.C</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {showsDomesticPartner && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              {bidDetail.accountPayables
                .filter((v) => {
                  return v.domain === "domestic";
                })
                .map((n) => {
                  return (
                    <Box
                      key={n.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Typography variant="body2" component="div">
                        국내파트너 : {n.partner?.name || "파트너 미지정"}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          onClick={handleSelectPartnerModalOpen(n.id, n.domain)}
                        >
                          수정
                        </Button>

                        {n.partner && (
                          <Button
                            variant="outlined"
                            onClick={handlesRequestEmailToPartnerModalOpen({
                              accountPayableId: n.id,
                              businessArea: n.domain,
                              language: n.partner.language,
                              partnerName: n.partner.name,
                            })}
                          >
                            컨택
                          </Button>
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </>
        )}

        {showsForeignPartner && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {bidDetail.accountPayables
              .filter((v) => {
                return v.domain === "foreign";
              })
              .map((n) => {
                return (
                  <Box
                    key={n.customsPartnerManagerId}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography variant="body2" component="div">
                      해외파트너 : {n.partner?.name || "파트너 미지정"}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={handleSelectPartnerModalOpen(n.id, n.domain)}
                      >
                        수정
                      </Button>

                      {n.partner && (
                        <Button
                          variant="outlined"
                          onClick={handlesRequestEmailToPartnerModalOpen({
                            accountPayableId: n.id,
                            businessArea: n.domain,
                            language: n.partner.language,
                            partnerName: n.partner.name,
                          })}
                        >
                          컨택
                        </Button>
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
      </Box>

      {showsRequestEmailToPartner &&
        (bidDetail.isImport ? (
          <RequestEmailToPartnerForImport />
        ) : (
          <RequestEmailToPartnerForExport />
        ))}

      {UploadResponseSnackBar}
    </Box>
  );
};

export default PartnerDesc;
