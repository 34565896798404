import { useAtomValue } from "jotai";

import ADMIN_ACCOUNT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_ACCOUNT_QUERY";
import { FORWARDING_ACCOUNT_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/account";

import useGetAccountParams from "./useGetAccountParams";

const useGetAccountList = () => {
  const { params } = useGetAccountParams();

  const selectedId = useAtomValue(FORWARDING_ACCOUNT_ATOMS.ACCOUNT_DETAIL_ID);

  const { data } = ADMIN_ACCOUNT_QUERY.useGetAccountList(params);

  return {
    data,
    selectedId,
  };
};

export default useGetAccountList;
