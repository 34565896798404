import { Button, Select, Table } from "antd";
import styled from "styled-components";

const businessManagementTableButton = styled(Button)`
  padding: 1px 8px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 2px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.6px;
  color: #1890ff;
  display: inline-block;
  cursor: pointer;
  height: 24px;
`;

const businessManagementExcelOpenButton = styled(Button)`
  width: 154px;
  height: 32px;
  margin-left: 16px;
  background: #1890ff;
  border-radius: 2;
  color: #ffffff;

  &:focus {
    border: 1px solid #74c041;
    color: #74c041;
  }
  &::selection {
    border: 1px solid #74c041;
    color: #74c041;
  }

  &:disabled {
    background-color: #e0e0e0;
  }

  &:hover {
    border: 1px solid #74c041;
    color: #74c041;
  }
`;

const businessManagementSelect = styled(Select)`
  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    padding: 5px 11px;
  }

  &.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 40px;
  }
`;

const salesTable = styled(Table)`
  font-size: 16px;
  font-family: Spoqa Han Sans;
  font-style: bold;

  &.salesTable thead tr:first-child th {
    border-left: none;
    background-color: #f0f2f5;
  }

  &.table-etc {
    font-size: 16px;
    font-family: Spoqa Han Sans;
    font-style: Bold;
  }
`;

export default {
  salesTable,
  businessManagementSelect,
  businessManagementExcelOpenButton,
  businessManagementTableButton,
};
