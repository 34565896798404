import { MouseEvent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Popover, Typography } from "@mui/material";
import { useSetRecoilState } from "recoil";

import {
  GET_PROFIT_LIST_RES,
  GET_PROFIT_LIST_SEARCH_KIND,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/businessManagement";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ADMIN_FORWARDING_MANAGER_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminAuth";
import { ADMIN_BID_SERVICE_TYPE_OPTION_LIST } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COMMON_QUERY";
import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/BUSINESS_MANAGEMENT_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import {
  BidProjectStatus,
  BidServiceType,
} from "@sellernote/_shared/src/types/forwarding/bid";
import { ProfitListItem } from "@sellernote/_shared/src/types/forwarding/businessManagement";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { changeBidProjectStatusNameToKr } from "@sellernote/_shared/src/utils/forwarding/bid";
import { changeServiceTypeToNameKr } from "@sellernote/_shared/src/utils/forwarding/businessManagement";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/trello";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";
import useGetCSVDownloadInfoFromQuery from "@sellernote/_shared-for-admin/src/hooks/common/useGetCSVDownloadInfoFromQuery";
import useTableHeadFilter from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";
import useSearchWithDate, {
  SearchWithDateTypeOption,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithDate";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-forwarding-admin/src/hooks/useSearchWithTerm";

import { numberFormat } from "services/util";

import SummaryInfo from "./SummaryInfo";

type CellId =
  | keyof ProfitListItem
  | "user"
  | "incoterms"
  | "startPort"
  | "serviceType"
  | "profitAmount"
  | "BL"
  | "createdAt"
  | "settlementDateAt"
  | "projectStatus"
  | "admin"
  | "endPort";

const termSearchTypeOptions: TermSearchType<GET_PROFIT_LIST_SEARCH_KIND>[] = [
  {
    label: "회사명",
    value: "company",
  },
  {
    label: "의뢰번호",
    value: "bidId",
  },
  {
    label: "BL",
    value: "BL",
  },
];

const dateSearchTypeOptions: SearchWithDateTypeOption<
  "createdAt" | "settlement"
>[] = [
  {
    label: "견적 생성일",
    value: "createdAt",
  },
  {
    label: "정산 요청일",
    value: "settlement",
  },
];

const Profit = () => {
  const history = useHistory();

  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const [perPage, setPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);

  const [showsSummary, setShowsSummary] = useState<HTMLButtonElement | null>(
    null
  );

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { DateSearchPanel, dateSearchType, startDate, endDate } =
    useSearchWithDate({
      dateSearchTypeOptions,
    });

  const { FilterPanel: ServiceTypeFilterPanel, filter: serviceTypeFilterData } =
    useTableHeadFilter({
      filterOptions: ADMIN_BID_SERVICE_TYPE_OPTION_LIST,
    });

  const {
    FilterPanel: ForwardingManagerFilterPanel,
    filter: forwardingManagerFilterData,
  } = useTableHeadFilter({
    filterOptions: ADMIN_FORWARDING_MANAGER_OPTION_LIST,
  });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { data: profitList } = BUSINESS_MANAGEMENT_QUERY.useGetProfitList({
    page: currentPage,
    perPage,
    isExcel: false,
    forwardingManagerId: forwardingManagerFilterData
      ? forwardingManagerFilterData[0]
      : undefined,
    serviceType: serviceTypeFilterData ? serviceTypeFilterData[0] : undefined,
    ...objectWithTermSearchTypeKey,
    ...(dateSearchType === "createdAt" && startDate && endDate
      ? {
          createdAtFromDate: new Date(startDate),
          createdAtToDate: new Date(endDate),
        }
      : {}),
    ...(dateSearchType === "settlement" && startDate && endDate
      ? {
          settlementFromDate: new Date(startDate),
          settlementToDate: new Date(endDate),
        }
      : {}),
  });

  const {
    isCSVDownloadRequested,
    setIsCSVDownloadRequested,
    dataForCSVDownload,
    ResponseHandlerOfFetchDataForCSVDownload,
    removeQueryOfFetchDataForCSVDownload,
    statusOfQueryOfFetchDataForCSVDownload,
  } = useGetCSVDownloadInfoFromQuery({
    query: BUSINESS_MANAGEMENT_QUERY.useGetProfitExcelList,
    queryArgs: [
      {
        isExcel: true,
        forwardingManagerId: forwardingManagerFilterData
          ? forwardingManagerFilterData[0]
          : undefined,
        serviceType: serviceTypeFilterData
          ? serviceTypeFilterData[0]
          : undefined,
        ...objectWithTermSearchTypeKey,
        ...(dateSearchType === "createdAt" && startDate && endDate
          ? {
              createdAtFromDate: new Date(startDate),
              createdAtToDate: new Date(endDate),
            }
          : {}),
        ...(dateSearchType === "settlement" && startDate && endDate
          ? {
              settlementFromDate: new Date(startDate),
              settlementToDate: new Date(endDate),
            }
          : {}),
      },
    ],
  });

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { data: summaryData } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementSummary();

  const hasSummaryData = useMemo(() => {
    const hasMonthInfo = Object.values(summaryData?.monthInfo || {}).some(
      (monthValue) => Boolean(Number(monthValue))
    );

    const hasYearInfo = Object.values(summaryData?.yearInfo || {}).some(
      (yearValue) => Boolean(Number(yearValue))
    );

    return hasMonthInfo || hasYearInfo;
  }, [summaryData]);

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "bidId",
        disablePadding: false,
        width: 100,
        label: "의뢰번호",
      },
      {
        id: "user",
        disablePadding: false,
        width: 120,
        label: "회사명",
      },
      {
        id: "serviceType",
        disablePadding: false,
        width: 100,
        label: "유형",
        filter: ServiceTypeFilterPanel,
      },
      {
        id: "isFirstOrder",
        disablePadding: false,
        width: 100,
        label: "최초진행",
      },
      {
        id: "incoterms",
        disablePadding: false,
        width: 100,
        label: "인코텀즈",
      },
      {
        id: "startPort",
        disablePadding: false,
        width: 100,
        label: "출발지",
      },
      {
        id: "endPort",
        disablePadding: false,
        width: 100,
        label: "도착지",
      },
      {
        id: "salesAmount",
        disablePadding: false,
        width: 120,
        label: "매출",
      },
      {
        id: "purchaseAmount",
        disablePadding: false,
        width: 120,
        label: "매입",
      },
      {
        id: "profitAmount",
        disablePadding: false,
        width: 120,
        label: "손익",
      },
      {
        id: "BL",
        disablePadding: false,
        width: 150,
        label: "BL NO",
      },
      {
        id: "createdAt",
        disablePadding: false,
        width: 120,
        label: "견적 생성일",
      },
      {
        id: "settlementDateAt",
        disablePadding: false,
        width: 120,
        label: "정산 요청일",
      },
      {
        id: "projectStatus",
        disablePadding: false,
        width: 150,
        label: "세부상태",
      },
      {
        id: "admin",
        disablePadding: false,
        width: 100,
        label: "담당자",
        filter: ForwardingManagerFilterPanel,
      },
    ];
  }, [ForwardingManagerFilterPanel, ServiceTypeFilterPanel]);

  const changePortIdToNameEN = useCallback(
    (portId: number) => {
      return portList?.find((portItem) => {
        return portId === portItem.id;
      })?.nameEN;
    },
    [portList]
  );

  const handleMoveToTrelloPageClick = useCallback(
    ({
      bidId,
      projectStatus,
      serviceType,
    }: {
      bidId: number;
      projectStatus: BidProjectStatus;
      serviceType: BidServiceType;
    }) => {
      return () => {
        setTrelloCardId(bidId);

        if (checkIsSettlement(projectStatus) && APP_NAME === "shipda-admin") {
          return history.push("/settlement");
        }

        if (serviceType === "consolidation") {
          return history.push("/trello/consolidation");
        }

        return history.push("/trello/general");
      };
    },
    [history, setTrelloCardId]
  );

  const mapDataForTable = useCallback(
    (data: GET_PROFIT_LIST_RES | undefined, isExcel: boolean) => {
      if (!data?.list) return [];

      return data?.list.map((profitListItem) => {
        const row: TableBodyRow<CellId> = {
          bidId: isExcel ? (
            profitListItem.bidId
          ) : (
            <Button
              variant="text"
              onClick={handleMoveToTrelloPageClick({
                bidId: profitListItem.bidId,
                projectStatus: profitListItem.bid.projectStatus,
                serviceType: profitListItem.bid.serviceType,
              })}
            >
              {profitListItem.bidId}
            </Button>
          ),
          company: profitListItem.company,
          serviceType: changeServiceTypeToNameKr(
            profitListItem.bid.serviceType
          ),
          isFirstOrder: changeBooleanValueToKr(profitListItem.isFirstOrder),
          incoterms: profitListItem.bid.incoterms,
          startPort:
            changePortIdToNameEN(profitListItem.bid.startPortId) ||
            changePortIdToNameEN(profitListItem.bid.startViaPortId),
          endPort:
            changePortIdToNameEN(profitListItem.bid.endPortId) ||
            changePortIdToNameEN(profitListItem.bid.endViaPortId),
          salesAmount: numberFormat(profitListItem.salesAmount),
          purchaseAmount: numberFormat(profitListItem.purchaseAmount),
          profitAmount: numberFormat(
            profitListItem.salesAmount - profitListItem.purchaseAmount
          ),
          BL:
            profitListItem.bid.BLType === "MBL" ||
            profitListItem.bid.BLType === "DirectMBL"
              ? profitListItem.bid.mBL
              : profitListItem.bid.hBL,
          createdAt: toFormattedDate(
            profitListItem.bid.createdAt,
            "YYYY.MM.DD a hh:mm:ss"
          ),
          settlementDateAt: toFormattedDate(
            profitListItem.createdAt,
            "YYYY.MM.DD a hh:mm:ss"
          ),
          projectStatus: changeBidProjectStatusNameToKr(
            profitListItem.bid.projectStatus
          ),
          admin: profitListItem.forwardingManagerName,
        };
        return row;
      });
    },
    [changePortIdToNameEN, handleMoveToTrelloPageClick]
  );

  const rowsForCSVDownload: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(dataForCSVDownload, true);
  }, [dataForCSVDownload, mapDataForTable]);

  const rows: TableBodyRow<CellId>[] = useMemo(() => {
    return mapDataForTable(profitList, false);
  }, [profitList, mapDataForTable]);

  const handleShowsSummaryOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setShowsSummary(e.currentTarget);
  };

  const handleShowsSummaryClose = () => {
    setShowsSummary(null);
  };

  return (
    <Layout title="손익 현황">
      <Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 1,
            p: 1,
            pt: 1.5,
            background: "#fff",
          }}
        >
          {TermSearchPanel}
          {DateSearchPanel}
        </Box>

        <Box sx={{ p: 1, background: "#fff" }}>
          <Table
            pagination={{
              rowsPerPageOptions: [10, 25, 50, 100, 500, 1000, 10000],
              totalCount: profitList?.total || 0,
              perPage,
              setPerPage,
              currentPage,
              setCurrentPage,
            }}
            csvDownloadInfo={{
              filename: `영업손익 데이터(${toFormattedDate(
                new Date(),
                "YYYY-MM-DD-HHmmss"
              )})`,
              scope: "all",
              isCSVDownloadRequested,
              setIsCSVDownloadRequested,
              rowsForCSVDownload,
              ResponseHandlerOfFetchDataForCSVDownload,
              statusOfQueryOfFetchDataForCSVDownload,
              removeQueryOfFetchDataForCSVDownload,
            }}
            sx={{ m: 1 }}
            headCells={headCells}
            rows={rows}
            toolbarItems={{
              left: [
                <Box
                  sx={{ display: "flex", gap: 1, alignItems: "center" }}
                  key="left"
                >
                  <Typography key="total">
                    총 {profitList?.total || 0}건
                  </Typography>

                  {hasSummaryData && (
                    <>
                      <Button
                        aria-describedby="bm-profit-summary"
                        variant="text"
                        onClick={handleShowsSummaryOpen}
                      >
                        누적정보 보기
                      </Button>

                      <Popover
                        id="bm-profit-summary"
                        anchorEl={showsSummary}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        open={Boolean(showsSummary)}
                        onClose={handleShowsSummaryClose}
                      >
                        <SummaryInfo summaryData={summaryData} />
                      </Popover>
                    </>
                  )}
                </Box>,
              ],
            }}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default withRequireAuth(Profit);
