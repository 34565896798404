import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { Box, Button } from "@mui/material";

import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_ORDER_QUERY";
import { AdminOrderDetail } from "@sellernote/_shared/src/types/forwarding/adminOrder";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

export default function usePartnerButtons(
  POId: number,
  orderDetail: AdminOrderDetail
) {
  const queryClient = useQueryClient();

  const [showsOrderPartnerModal, setShowsOrderPartnerModal] = useState(false);

  const [showsRequestEmailToPartner, setShowsRequestEmailToPartner] =
    useState(false);

  const [businessArea, setBusinessArea] = useState<"domestic" | "foreign">();

  const handlePartnerModalOpen = (partnerDomain: PartnerBusinessArea) => {
    setBusinessArea(partnerDomain === "domestic" ? "domestic" : "foreign");
    setShowsOrderPartnerModal(true);
  };

  const {
    UploadResponseSnackBar,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
    setErrorMessage,
  } = useUploadResponseSnackBar();

  const { mutate: contactPartnerEmail } =
    ADMIN_ORDER_QUERY.useContactPartner(POId);

  const handleContactPartnerEmail = useCallback(() => {
    contactPartnerEmail(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
              id: POId,
            })
          );
          setShowsSuccessSnackBar(true);
        },
        onError: () => {
          setErrorMessage("에러가 있습니다 개발자에게 문의해주세요");
          setShowsErrorSnackBar(true);
        },
      }
    );
  }, [
    contactPartnerEmail,
    POId,
    queryClient,
    setErrorMessage,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
  ]);
  const EditPartnerAndContactButton = useCallback(
    ({
      partnerDomain,
      hasPartner,
    }: {
      partnerDomain: PartnerBusinessArea;
      hasPartner: string | number | boolean | undefined | JSX.Element;
    }) => {
      return (
        <Box sx={{ display: "flex", gap: 1 }}>
          {UploadResponseSnackBar}

          <Button
            variant="outlined"
            size="small"
            disabled={orderDetail.poStatus === "registered"}
            onClick={() => handlePartnerModalOpen(partnerDomain)}
          >
            수정
          </Button>

          {hasPartner && (
            <Button
              variant="contained"
              size="small"
              disabled={orderDetail.poStatus === "registered"}
              onClick={handleContactPartnerEmail}
            >
              컨택
            </Button>
          )}
        </Box>
      );
    },
    [UploadResponseSnackBar, handleContactPartnerEmail, orderDetail.poStatus]
  );

  return {
    EditPartnerAndContactButton,
    showsOrderPartnerModal,
    showsRequestEmailToPartner,
    setShowsOrderPartnerModal,
    setShowsRequestEmailToPartner,
    businessArea,
  };
}
