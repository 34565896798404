import React, { ChangeEvent, useState } from "react";
import { useAtomValue } from "jotai";

import BUSINESS_MANAGEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/BUSINESS_MANAGEMENT_QUERY";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";
import Modal from "@sellernote/_shared-for-forwarding-admin/src/components/Modal";
import CommentDataList from "@sellernote/_shared-for-forwarding-admin/src/hooks/useCommentList/CommentDataList";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

const CommentModal = ({
  setShowsCommentModal,
  showsCommentModal,
  bidDetailId,
}: {
  setShowsCommentModal: React.Dispatch<React.SetStateAction<boolean>>;
  showsCommentModal: boolean;
  bidDetailId: number;
}) => {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const { data: commentData, refetch: refetchCommentData } =
    BUSINESS_MANAGEMENT_QUERY.useGetBusinessManagementCommentList({
      bidId: bidDetailId,
    });

  const { mutate: updateComment } = TRELLO_BID_QUERY.useUpdateComment();

  const {
    UploadResponseSnackBar,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
  } = useUploadResponseSnackBar();

  const [comment, setComment] = useState("");

  const handleCommentInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleCommentUpdate = () => {
    if (!bidDetailId) {
      return;
    }

    updateComment(
      {
        pathParams: {
          bidId: bidDetailId,
        },
        comment: {
          id: currentAdminAuthInfo?.accountId || "-",
          comment,
          date: new Date().toISOString(),
        },
      },
      {
        onSuccess: () => {
          setComment("");
          setShowsSuccessSnackBar(true);
          refetchCommentData();
        },

        onError: () => {
          setShowsErrorSnackBar(true);
          return;
        },
      }
    );
  };

  return (
    <Modal
      isOpened={showsCommentModal}
      handleClose={() => setShowsCommentModal(false)}
      modalBody={
        commentData && (
          <CommentDataList
            comment={comment}
            onCommentInputChange={handleCommentInputChange}
            onCommentUpdate={handleCommentUpdate}
            commentData={commentData.comments}
            UploadResponseSnackBar={UploadResponseSnackBar}
          />
        )
      }
    ></Modal>
  );
};

export default CommentModal;
