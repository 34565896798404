import { useCallback, useMemo, useState } from "react";
import { Control, UseFormSetValue, useWatch } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";

import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { BidCreateFormData } from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import {
  calculateCBM,
  calculateCW,
  calculateRTon,
  toFixedFloat,
  toKg,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";
import TextFieldWithReactHookForm from "@sellernote/_shared-for-forwarding-admin/src/components/TextFieldWithReactHookForm";

function ItemTotalSummary({
  control,
  setValue,
}: {
  control: Control<BidCreateFormData>;
  setValue: UseFormSetValue<BidCreateFormData>;
}) {
  const freightType = useWatch({
    control,
    name: "freightType",
  });

  const productsInfo = useWatch({
    control,
    name: "productsInfo",
  });

  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const getTotalCbm = useCallback(() => {
    return productsInfo?.reduce((acc, cur) => {
      const calculatedCBM =
        calculateCBM({
          type: "lcl",
          width: cur.horizontal || 0,
          height: cur.height || 0,
          depth: cur.vertical || 0,
          sizeUnit: cur.volumeUnit,
        }) || 0;

      const cbm = calculatedCBM * cur.quantity ?? 0;

      acc += cbm;

      return acc;
    }, 0);
  }, [productsInfo]);

  const totalCbm = useMemo(() => {
    const cbm = getTotalCbm();

    return toFixedFloat(cbm ?? 0, 2) ?? 0;
  }, [getTotalCbm]);

  const totalWeight = useMemo(() => {
    return productsInfo?.reduce((acc, cur) => {
      if (freightType === "LCL") {
        const weight = toTon(cur.weight, cur.weightUnit) * cur.quantity;

        acc += weight;
      }

      if (freightType === "AIR") {
        const weight = toKg(cur.weight, cur.weightUnit) * cur.quantity;

        acc += weight;
      }

      return toFixedFloat(acc, 2) ?? 0;
    }, 0);
  }, [freightType, productsInfo]);

  const totalRtonOrCW = useMemo(() => {
    if (freightType === "LCL") {
      if (
        cargoInfoFormType === "totalVolume" &&
        productsInfo &&
        productsInfo?.length > 0
      ) {
        return calculateRTon(
          productsInfo[0].cbm,
          toTon(productsInfo[0].weight, productsInfo[0].weightUnit)
        );
      }
      return calculateRTon(totalCbm ?? 0, totalWeight ?? 0);
    }

    if (cargoInfoFormType === "totalVolume" && productsInfo) {
      return (
        toFixedFloat(
          calculateCW({
            type: "cbm",
            cbm: productsInfo[0].cbm,
            weight: toKg(productsInfo[0].weight, productsInfo[0].weightUnit),
          }),
          2
        ) ?? 0
      );
    }

    return (
      toFixedFloat(
        calculateCW({
          type: "cbm",
          cbm: getTotalCbm() ?? 0,
          weight: toKg(
            totalWeight ?? 0,
            productsInfo ? productsInfo[0].weightUnit : "KG"
          ),
        }),
        2
      ) ?? 0
    );
  }, [
    cargoInfoFormType,
    freightType,
    getTotalCbm,
    productsInfo,
    totalCbm,
    totalWeight,
  ]);

  const [prevTotalCbm, setPrevTotalCbm] = useState(totalCbm);

  const [prevTotalWeight, setPrevTotalWeight] = useState(totalWeight);

  const [prevTotalRtonOrCw, setPrevTotalRtonOrCw] = useState(totalRtonOrCW);

  // To locate the bad setState()~ 에러를 없애기 위해서 callback-ref 사용
  const itemTotalSummaryRef = useCallback(
    (node) => {
      if (node !== null) {
        if (totalCbm !== prevTotalCbm) {
          setPrevTotalCbm(totalCbm);
          setValue("totalCBM", totalCbm);
        }

        if (totalWeight !== prevTotalWeight) {
          setPrevTotalWeight(totalWeight);
          setValue("totalWeight", totalWeight);
        }

        if (totalRtonOrCW !== prevTotalRtonOrCw) {
          setPrevTotalRtonOrCw(totalRtonOrCW);
          setValue("supply", totalRtonOrCW);
        }
      }
    },
    [
      totalCbm,
      prevTotalCbm,
      totalWeight,
      prevTotalWeight,
      totalRtonOrCW,
      prevTotalRtonOrCw,
      setValue,
    ]
  );

  return (
    <Grid ref={itemTotalSummaryRef} item container>
      {cargoInfoFormType === "packaging" && (
        <Grid item container>
          <Grid item xs={1}>
            <Typography variant="subtitle1" component="span">
              총 CBM
            </Typography>
          </Grid>
          <Grid item>
            <TextFieldWithReactHookForm name={"totalCBM"} control={control} />
          </Grid>
        </Grid>
      )}

      {cargoInfoFormType === "packaging" && (
        <Grid item container>
          <Grid item xs={1}>
            <Typography variant="subtitle1" component="span">
              {freightType === "LCL" ? "총 TON" : "총 KG"}
            </Typography>
          </Grid>

          <Grid item>
            <TextFieldWithReactHookForm
              name={"totalWeight"}
              control={control}
            />
          </Grid>
        </Grid>
      )}

      <Grid item container>
        <Grid item xs={1}>
          <Typography variant="subtitle1" component="span">
            {freightType === "LCL" ? "총 R.TON" : "총 C.W"}
          </Typography>
        </Grid>

        <Grid item>
          <TextFieldWithReactHookForm name={"supply"} control={control} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ItemTotalSummary;
