import { useCallback } from "react";
import { useForm } from "react-hook-form";

import {
  EDIT_BID_QUOTE_DRAFT_REQ,
  EDIT_BID_QUOTE_DRAFT_REQ_PATH_PARAMS,
  GET_ADMIN_BID_APPLY_ITEMS_RES,
  GET_ADMIN_BID_DETAIL_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { Liner } from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import {
  getInlandType,
  getLinerId,
  getQuotationDataCurrency,
  replaceBrTagsWithNewline,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import {
  findExchangeRate,
  getAllTotalItemPriceOfFeeData,
} from "@sellernote/_shared/src/utils/forwarding/tradingStatement";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";
import useGetNewCurrencyQuotation from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetNewCurrencyQuotation";

import BidQuotation from "pages/bid/BidQuotation";

function ChangeQuotation({
  bidDetail,
  exchangeRateList,
  applyItems,
  linerList,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
  exchangeRateList: ExchangeRate[];
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES;
  linerList: Liner[];
}) {
  const { mutate: editBidQuoteDraft, isLoading } =
    ADMIN_BID_QUERY.useEditBidQuoteDraft();

  const { mutate: editExportBidQuoteDraft, isLoading: isExportLoading } =
    ADMIN_BID_QUERY.useEditExportBidQuoteDraft();

  const {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
  } = useUploadResponseSnackBar();

  const { quotationWithIsTaxExempt } = useGetNewCurrencyQuotation({
    bidDetail,
    exchangeRateList,
    type: "draft",
  });

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ApplyBidFormData>({
    defaultValues: {
      otherFeeData: quotationWithIsTaxExempt.otherFee || [],
      freightPaymentType: quotationWithIsTaxExempt.freightPaymentType,
      liner: quotationWithIsTaxExempt.liner,
      freightFeeData: quotationWithIsTaxExempt.freightFee || [],
      domesticFeeData: quotationWithIsTaxExempt.domesticFee || [],
      inlandFeeData: quotationWithIsTaxExempt.inlandFee || [],
      localFeeData: quotationWithIsTaxExempt.localFee || [],
      taxFeeData: quotationWithIsTaxExempt.taxFee || [],
      leadtime: quotationWithIsTaxExempt.leadtime,
      comment: replaceBrTagsWithNewline(quotationWithIsTaxExempt.comment),
      expiredAt: quotationWithIsTaxExempt.expiredAt,
      isTransit: quotationWithIsTaxExempt.isTransit,
    },
  });

  const handleQuotationForwardersChange = useCallback(() => {
    if (bidDetail.quotationForwarders) {
      const requestPayload: EDIT_BID_QUOTE_DRAFT_REQ & {
        pathParams: EDIT_BID_QUOTE_DRAFT_REQ_PATH_PARAMS;
      } = {
        pathParams: {
          bidId: bidDetail.id,
          //견적서 데이터 중 가장 앞에 있는 데이터를 사용한다.
          quotationId: bidDetail.quotationForwarders[0].id,
        },
        quotationData: {
          ...bidDetail.quotationForwarders[0],
          freightPaymentType: watch("freightPaymentType"),
          liner: watch("liner"),
          isTransit: watch("isTransit"),
          exchangeRate: findExchangeRate(exchangeRateList, "USD"),
          totalPrice: getAllTotalItemPriceOfFeeData(
            watch("freightFeeData"),
            watch("domesticFeeData"),
            watch("localFeeData"),
            watch("inlandFeeData"),
            watch("otherFeeData"),
            watch("taxFeeData")
          ),
          freightFee: watch("freightFeeData"),
          localFee: watch("localFeeData"),
          domesticFee: watch("domesticFeeData"),
          inlandFee: watch("inlandFeeData"),
          taxFee: watch("taxFeeData"),
          otherFee: watch("otherFeeData"),
          comment: watch("comment"),
          expiredAt: watch("expiredAt"),
          leadtime: watch("leadtime"),
          currency: getQuotationDataCurrency({
            localFee: watch("localFeeData"),
            freightFee: watch("freightFeeData"),
            domesticFee: watch("domesticFeeData"),
            inlandFee: watch("inlandFeeData"),
            otherFee: watch("otherFeeData"),
            taxFee: watch("taxFeeData"),
            exchangeRateList: exchangeRateList,
          }),
          linerId: getLinerId(linerList, watch("liner")),
        },

        // 수출 요청에서는 자동적으로 inlandType을 설정해줌
        inlandType: bidDetail.isImport
          ? getInlandType(watch("inlandFeeData"), bidDetail.freightType)
          : undefined,
      };

      if (bidDetail.isImport) {
        editBidQuoteDraft(
          {
            ...requestPayload,
          },
          {
            onSuccess: () => {
              setShowsSuccessSnackBar(true);
            },

            onError: () => {
              setShowsErrorSnackBar(true);
            },
          }
        );

        return;
      }

      editExportBidQuoteDraft(
        {
          ...requestPayload,
        },
        {
          onSuccess: () => {
            setShowsSuccessSnackBar(true);
          },

          onError: () => {
            setShowsErrorSnackBar(true);
          },
        }
      );
    }

    return;
  }, [
    bidDetail.freightType,
    bidDetail.id,
    bidDetail.isImport,
    bidDetail.quotationForwarders,
    editBidQuoteDraft,
    editExportBidQuoteDraft,
    exchangeRateList,
    linerList,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
    watch,
  ]);

  return (
    <>
      <BidQuotation
        bidDetail={bidDetail}
        exchangeRateList={exchangeRateList}
        control={control}
        watch={watch}
        reset={reset}
        setValue={setValue}
        handleSubmit={handleSubmit}
        errors={errors}
        submitFunction={handleQuotationForwardersChange}
        setShowsSuccessSnackBar={setShowsSuccessSnackBar}
        setShowsErrorSnackBar={setShowsErrorSnackBar}
        applyItems={applyItems}
        linerList={linerList}
        quotationType={"change"}
        isLoading={bidDetail.isImport ? isLoading : isExportLoading}
      />

      {UploadResponseSnackBar}
    </>
  );
}

export default ChangeQuotation;
