import { useCallback, useState } from "react";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";

import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getTransportMode } from "@sellernote/_shared/src/utils/forwarding/adminBid";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import PartnerQuoteListModal from "./PartnerQuoteListModal";
import QuotePartnerRadioList from "./QuotePartnerRadioList";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

function PartnerQuote({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const [showsPartnerQuoteListModal, setShowsPartnerQuoteListModal] =
    useState(false);

  const { data: partnerQuote, refetch: refetchPartnerQuote } =
    ADMIN_BID_QUERY.useGetAdminBidPartnerQuote({
      bidId: bidDetail.id,
    });

  const { mutate: resetPartnerQuote } = ADMIN_BID_QUERY.useResetPartnerQuote(
    bidDetail.id
  );

  const {
    UploadResponseSnackBar,
    setSuccessMessage,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
  } = useUploadResponseSnackBar();

  const handlePartnerQuoteListRefetch = () => {
    refetchPartnerQuote();
  };

  const partnerName = (() => {
    if (!partnerQuote?.list || partnerQuote.list.length < 1) {
      return <Typography>견적 파트너 없음</Typography>;
    }

    return (
      <QuotePartnerRadioList
        partnerList={partnerQuote.list}
        shipmentId={bidDetail.id}
        onPartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
        bidStatus={bidDetail.status}
      />
    );
  })();

  const bidTransportMode = getTransportMode(
    bidDetail.freightType,
    bidDetail.serviceType
  );

  const handlePartnerQuoteListModalOpen = () => {
    setShowsPartnerQuoteListModal(true);
  };

  const handlePartnerQuoteReset = useCallback(() => {
    resetPartnerQuote(bidDetail.id, {
      onSuccess: () => {
        setSuccessMessage("견적 파트너를 초기화 했습니다.");
        setShowsSuccessSnackBar(true);
        refetchPartnerQuote();
      },

      onError: () => {
        setShowsErrorSnackBar(true);
      },
    });
  }, [
    bidDetail.id,
    refetchPartnerQuote,
    resetPartnerQuote,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
    setSuccessMessage,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h6">파트너 견적 문의</Typography>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography>파트너:</Typography>

          <Button
            variant="contained"
            onClick={handlePartnerQuoteListModalOpen}
            disabled={
              bidDetail.status === "inProgress" ||
              bidDetail.status === "finished"
            }
          >
            선택
          </Button>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography>견적 파트너:</Typography>

          <Box>{partnerName}</Box>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography>문의 초기화:</Typography>

          <Button
            variant="contained"
            color="error"
            onClick={handlePartnerQuoteReset}
            disabled={
              bidDetail.status === "inProgress" ||
              bidDetail.status === "finished"
            }
          >
            초기화
          </Button>
        </Box>
      </Box>

      {showsPartnerQuoteListModal && (
        <PartnerQuoteListModal
          transportMode={bidTransportMode}
          showsPartnerQuoteListModal={showsPartnerQuoteListModal}
          setShowsPartnerQuoteListModal={setShowsPartnerQuoteListModal}
          bidDetail={bidDetail}
          handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
        />
      )}

      {UploadResponseSnackBar}
    </Box>
  );
}

export default PartnerQuote;
