import { Dispatch, SetStateAction } from "react";
import { useQueryClient } from "react-query";
import { Button, Modal, Table } from "antd";
import { AxiosError } from "axios";

import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_COUPON_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_COUPON_QUERY";
import { sendRequest } from "@sellernote/_shared/src/services/request";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { UserCoupon } from "@sellernote/_shared/src/types/forwarding/coupon";

import { success, warning } from "services/errorMessage";

function UserCouponModal({
  showUserCouponModal,
  setShowUserCouponModal,
  id,
  freightType,
  bidId,
}: {
  showUserCouponModal: boolean;
  setShowUserCouponModal: Dispatch<SetStateAction<boolean>>;
  id: number;
  freightType: FreightType;
  bidId: number;
}) {
  const queryClient = useQueryClient();

  const { data: couponList, isFetching } =
    ADMIN_COUPON_QUERY.useGetAdminUserCouponList({
      userId: id,
      couponType: freightType,
    });

  const registerCoupon = async (couponCode: string) => {
    try {
      await sendRequest({
        method: "put",
        apiType: "ShipdaAdminDefaultNew",
        path: `/coupon/use/${bidId}`,
        data: {
          couponCode,
        },
      });
      queryClient.invalidateQueries(
        ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
          bidId,
        })
      );
      success("쿠폰을 등록했습니다.");
      setShowUserCouponModal(false);
    } catch (e) {
      const err = e as AxiosError;
      if (err.response?.data.errorCode === "E314") {
        warning("이미 발급된 쿠폰입니다..");
      } else {
        Modal.error({
          title: "쿠폰등록에 실패했습니다.",
          content: "개발자에게 문의해주세요.",
        });
      }
    }
  };

  const columns = [
    {
      title: "쿠폰명",
      render: (record: UserCoupon) => {
        return (
          <div>
            <div>{record.campaign.campaignName}</div>
          </div>
        );
      },
    },
    {
      title: "쿠폰 운송유형",
      dataIndex: "couponType",
    },
    {
      title: "코드",
      dataIndex: "code",
    },
    {
      title: "할인 금액",
      dataIndex: "discountPrice",
    },
    {
      title: "쿠폰 만료일",
      render: (record: UserCoupon) => {
        const date = new Date(record.dueDate);
        return date.toLocaleDateString();
      },
    },
    {
      title: "쿠폰 등록",
      render: (record: UserCoupon) => {
        return (
          <Button onClick={() => registerCoupon(record.code)}>등록하기</Button>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        width={800}
        visible={showUserCouponModal}
        onCancel={() => setShowUserCouponModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowUserCouponModal(false)}>
            돌아가기
          </Button>,
        ]}
      >
        {couponList && !isFetching && (
          <Table
            rowKey={(record: UserCoupon) => record.id}
            dataSource={couponList.list}
            columns={columns}
          />
        )}
      </Modal>
    </div>
  );
}

export default UserCouponModal;
