import { useState } from "react";
import { useHistory } from "react-router-dom";
import { AlignLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, message, Typography } from "antd";

import { sendRequest } from "@sellernote/_shared/src/services/request";
import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

const { Text } = Typography;

const error = () => {
  message.error("오류가 있습니다 빈칸이 있는지 확인해주세요");
};

const AdminUserRegister = () => {
  const [adminAuthority, setAdminAuthority] = useState("");
  const [adminName, setadminName] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminId, setAdminId] = useState("");

  const history = useHistory();

  const createAdmin = async () => {
    try {
      await sendRequest({
        method: "post",
        path: `/auth/admins`,
        apiType: "ShipdaAdminDefaultNew",
        data: {
          accountId: adminId,
          name: adminName,
          email: adminEmail,
          authority: adminAuthority,
          password: adminPassword,
        },
      });
      history.go(-1);
    } catch (err) {
      error();
    }
  };

  const onSelectAuthority = (value: any) => {
    let authorityName = "";
    if (value === "관리자") {
      authorityName = "master";
    } else if (value === "매니저") {
      authorityName = "manager";
    } else if (value === "정산 담당자") {
      authorityName = "finance";
    }
    setAdminAuthority(authorityName);
  };

  const handleChangeAdminName = (e: any) => {
    setadminName(e.target.value);
  };

  const handleChangeAdminPassword = (e: any) => {
    setAdminPassword(e.target.value);
  };

  const authority = [
    { value: "관리자" },
    { value: "매니저" },
    { value: "정산 담당자" },
  ];

  return (
    <Layout breadcrumbs={["사용자 관리"]} title={"사용자 추가"}>
      <Button onClick={() => history.go(-1)} style={{ marginLeft: "20px" }}>
        <AlignLeftOutlined />
        목록
      </Button>
      <div style={{ padding: "20px" }}>
        <Text strong>권한</Text>
        <br />
        <AutoComplete
          style={{
            width: "300px",
            marginTop: "5px",
          }}
          placeholder="권한"
          onSelect={onSelectAuthority}
          dataSource={authority}
        />
        <br />
        <br />
        <Text strong>아이디</Text>
        <br />
        <Input
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="아이디"
          onChange={(e) => setAdminId(e.target.value)}
          allowClear
        />
        <br />
        <br />
        <Text strong>이름</Text>
        <br />
        <Input
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="이름"
          onChange={handleChangeAdminName}
          allowClear
        />
        <br />
        <br />
        <Text strong>이메일</Text>
        <br />
        <Input
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="이메일"
          onChange={(e) => setAdminEmail(e.target.value)}
          allowClear
        />
        <br />
        <br />
        <Text strong>비밀번호</Text>
        <br />
        <Input
          type="password"
          style={{ width: "300px", marginTop: "5px" }}
          placeholder="비밀번호"
          onChange={handleChangeAdminPassword}
          allowClear
        />
        <br />
        <br />
        <Button onClick={createAdmin} type="primary">
          <SaveOutlined /> 생성
        </Button>
      </div>
    </Layout>
  );
};

export default withRequireAuth(AdminUserRegister);
